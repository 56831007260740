import React, { useState, useEffect } from "react";
import { CLPublicKey } from "casper-js-sdk";

import { getOwned } from "../../api";
import BannerProfile from "../../molecules/banner-profile";
import ProfileCollection from "../../molecules/profile-collection";
import { NFT_CATEGORIES_ARRAY } from "../../constants";

//Import owner's NFt - API call in the future
// import myAllNfts from "../../data.json";

import {
  CaneLeft,
  CaneRight,
  Collections,
  GreenBoxFake,
  GreenBoxFake2,
  NoNftsCollected,
  ProfilePageWrapper,
} from "./styles";

import CaneSrc from "../../img/assets/cane-img.svg";

const ProfilePage = ({ signerPubKey }) => {
  const [myAllNfts, setMyAllNfts] = useState([]);

  useEffect(() => {
    if (signerPubKey) {
      getOwned(CLPublicKey.fromHex(signerPubKey).toAccountHashStr()).then(setMyAllNfts);
    }
  }, []);

  //Filter unique
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const myAllNftsCategories = myAllNfts.map((nft) => {
    return nft.meta.category;
  });

  //All my unique categories array
  const uniqueMyAllNftsCategories = myAllNftsCategories
    .filter(onlyUnique)
    .sort();

  //Devide nfts for specific collections
  const collections = [];
  for (let i = 0; i < uniqueMyAllNftsCategories.length; i++) {
    collections.push(
      <ProfileCollection
        key={i}
        title={NFT_CATEGORIES_ARRAY[uniqueMyAllNftsCategories[i]]}
        filteredData={myAllNfts.filter(
          (e) => e.meta.category === uniqueMyAllNftsCategories[i]
        )}
      />
    );
  }

  return (
    <ProfilePageWrapper>
      <BannerProfile />
      <Collections>
        {collections.length === 0 ? (
          <NoNftsCollected>No Nfts collected</NoNftsCollected>
        ) : (
          collections
        )}
        <CaneLeft src={CaneSrc} />
        <CaneRight src={CaneSrc} />
      </Collections>
      {collections.length === 0 ? <GreenBoxFake2 /> : <GreenBoxFake />}
    </ProfilePageWrapper>
  );
};

export default ProfilePage;
